import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AmaIconComponent } from '../icon/icon.component';
import { COMMON_DIRECTIVES } from '../../../app/shared/basic';

@Component({
    selector: 'ama-banner',
    templateUrl: 'banner.component.html',
    styleUrls: ['banner.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [COMMON_DIRECTIVES, AmaIconComponent]
})
export class AmaBannerComponent {
  @Input() public bannerImageUrl = '';
  @Input() public buttonText = '';
  @Input() public icon = '';
  @Input() public buttonIcon = '';
  @Input() public color = 'primary';
  @Input() public showCloseButton = false;
  @Input() public buttonStyle: 'round' | 'square' = 'round';
  @Output() public ctaClicked = new EventEmitter();
  @Output() public bannerClosed = new EventEmitter();

  public bannerButtonClick() {
    this.ctaClicked.emit();
  }

  public closeBanner() {
    this.bannerClosed.emit();
  }

  public hasBannerImage() {
    return this.bannerImageUrl && this.bannerImageUrl.length > 0;
  }
}
